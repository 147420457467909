import { Clinic, Patient } from "@/shared/api-client";
import { clinicianCognitoConfig } from "@/shared/aws-exports";
import { ResourcesConfig } from "aws-amplify";

const ACTIVE_ICON_PATH = "/icon-active.svg";
const ALERT_ICON_PATH = "/icon-alert.svg";
const WARNING_ICON_PATH = "/icon-warning.svg";
const NO_DATA_ICON_PATH = "/icon-no-data.svg";

const HARDWARE_TYPES = [
  {
    code: "01",
    type: "patch",
  },
  {
    code: "02",
    type: "hub",
  },
  {
    code: "03",
    type: "patchbox",
  },
  {
    code: "06",
    type: "hub",
  },
  {
    code: "07",
    type: "kit",
  },
  {
    code: "09",
    type: "kit",
  },
];

export const getEventIcon = (eventType) => {
  switch (eventType) {
    case "dialysis":
      return "/dialysis-icon-kidneys.svg";
    case "emergency_room":
      return "/er-icon.svg";
    case "lab_draw":
      return "/blood-draw-icon.svg";
    case "esa_dose":
      return "/rx-icon.svg";
    case "iv_iron_dose":
      return "/rx-icon.svg";
    case "vascular_access_intervention":
      return "/vascular-access-icon.svg";
    case "transfusion":
      return "/transfusion-icon.svg";
    case "hospitalization_admission":
      return "/in-hospital-icon.svg";
    case "hospitalization_discharge":
      return "/out-hospital-icon.svg";
    case "other":
      return "/other-icon.svg";
    case "highpotassium":
      return "/bell-alert-icon.svg";
    case "lowpotassium":
      return "/bell-alert-icon.svg";
    case "dyskalemia":
      return "/bell-alert-icon.svg";
    case "highhgb":
      return "/bell-alert-icon.svg";
    case "lowhgb":
      return "/bell-alert-icon.svg";
    case "highhct":
      return "/bell-alert-icon.svg";
    case "lowhct":
      return "/bell-alert-icon.svg";
  }
};

// get the events to exclude based on the chart type
// for potassium we don't want to see hematocrit, etc
export const getExcludeEvents = (chartType) => {
  if (chartType === "potassium") {
    return ["highhgb", "lowhgb", "highhct", "lowhct"];
  } else if (chartType === "hgb") {
    return ["highpotassium", "lowpotassium", "dyskalemia", "highhct", "lowhct"];
  } else if (chartType === "hct") {
    return ["highpotassium", "lowpotassium", "dyskalemia", "highhgb", "lowhgb"];
  } else {
    return [
      "highpotassium",
      "lowpotassium",
      "dyskalemia",
      "highhgb",
      "lowhgb",
      "highhct",
      "lowhct",
    ];
  }
};

export const round = (num, precision) => {
  const modifier = 10 ** precision;
  return Math.round(num * modifier) / modifier;
};

// @ts-ignore
export const debounce = (func, delay, { leading } = {}) => {
  let timerId;

  return function (...args) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    if (!timerId && leading) {
      func.apply(context, args);
    }
    clearTimeout(timerId);

    timerId = setTimeout(() => func.apply(context, args), delay);
  };
};

export const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    const groupKey =
      typeof key === "function" ? key(currentValue) : currentValue[key];

    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    result[groupKey].push(currentValue);
    return result;
  }, {});
};

export const getClinicianAmplifyConfig = (store) => {
  const siteConfig = store.getters.siteConfig;
  const site = store.getters.site;
  if (
    siteConfig &&
    fieldExists(siteConfig, "Id") &&
    fieldExists(siteConfig, "ClientId")
  ) {
    return {
      Auth: {
        Cognito: {
          userPoolId: siteConfig.Id ? siteConfig.Id : "",
          userPoolClientId: siteConfig.ClientId ? siteConfig.ClientId : "",
          loginWith: {
            oauth: {
              domain: `${siteConfig.Domain}.auth.us-west-2.amazoncognito.com`,
              scopes: [
                "phone",
                "email",
                "profile",
                "openid",
                "aws.cognito.signin.user.admin",
              ],
              redirectSignIn: [
                `${
                  import.meta.env.VITE_CLINICIAN_USER_REDIRECT_LOGIN_URI
                }${site}/sso`,
              ],
              redirectSignOut: [
                `${
                  import.meta.env.VITE_CLINICIAN_USER_REDIRECT_LOGIN_URI
                }${site}`,
              ],
              responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
            },
          },
        },
      },
    } as ResourcesConfig;
  } else {
    return clinicianCognitoConfig;
  }
};

export const fieldExists = (jsonObject: object, key: string): boolean => {
  if (!jsonObject || !key) {
    return false;
  }

  const keysArray = key.split(".");
  let baseJsonObject: any = jsonObject;

  for (const item of keysArray) {
    if (baseJsonObject != null && item in baseJsonObject) {
      baseJsonObject = baseJsonObject[item];
    } else {
      return false;
    }
  }

  return true;
};
export const getField = (jsonObject: object, key: string) => {
  if (!jsonObject || !key) {
    return null;
  }

  const keysArray = key.split(".");
  let baseJsonObject: any = jsonObject;

  for (const item of keysArray) {
    if (baseJsonObject != null && item in baseJsonObject) {
      baseJsonObject = baseJsonObject[item];
    } else {
      return null;
    }
  }

  return baseJsonObject;
};
export const setField = (jsonObject: any, key: string, value: any) => {
  if (!jsonObject || !key) {
    return null;
  }

  const keysArray = key.split(".");
  const nextKey: string | undefined = keysArray.shift();

  if (nextKey) {
    if (keysArray.length === 0) {
      jsonObject[nextKey] = value;
    } else {
      jsonObject[nextKey] = setField(
        getField(jsonObject, nextKey) || {},
        keysArray.join("."),
        value
      );
    }
  }

  return jsonObject;
};

export const isSupportApp = (context: any) => {
  // appType is setup in the main.js file
  if (context.$APP_TYPE === "support") {
    return true;
  }

  return false;
};
export const isClinicianApp = (context: any) => {
  // appType is setup in the main.js file
  if (context.$APP_TYPE === "clinician") {
    return true;
  }

  return false;
};

// gsn stands for global serial number
export const getHardwareType = (gsn: string) => {
  for (const item of HARDWARE_TYPES) {
    if (item.code === gsn.substring(4, 6)) return item.type;
  }
  return null;
};

export const isValidHardwareType = (hardwareType: string) => {
  for (const item of HARDWARE_TYPES) {
    if (item.code === hardwareType) return true;
  }
  return false;
};

export const isValidGSN = (gsn: string) => {
  // should be only digits
  const regex = /^[0-9]+$/;
  if (!regex.test(gsn)) return false;
  // should be at least 10 digits
  if (gsn.length < 10) return false;
  // 12 digits is our current max length according to our specifications
  if (gsn.length > 12) return false;
  // should be valid hardware type
  // if (!isValidHardwareType(gsn.substring(4, 6))) return false;
  return true;
};

export const getChangedProperties = (originalObject, newObject) => {
  const changes = {};

  // Helper function to check if values are objects for recursive comparison
  function isObject(obj) {
    return obj !== null && typeof obj === "object";
  }

  // Updated isEqual function to handle nested objects
  function isEqual(value1, value2) {
    if (isObject(value1) && isObject(value2)) {
      return JSON.stringify(value1) === JSON.stringify(value2); // Simplistic deep equal, consider a more robust approach for production
    }
    return value1 === value2;
  }

  // Recursive function to check all properties including nested ones
  function checkChanges(obj1, obj2, accumulator) {
    Object.keys(obj2).forEach((key) => {
      if (
        isObject(obj2[key]) &&
        isObject(obj1[key]) &&
        !Array.isArray(obj2[key])
      ) {
        // Initialize a nested accumulator for nested objects
        const nestedChanges = {};
        checkChanges(obj1[key], obj2[key], nestedChanges);
        if (Object.keys(nestedChanges).length > 0) {
          accumulator[key] = nestedChanges;
        }
      } else if (!isEqual(obj2[key], obj1[key])) {
        accumulator[key] = obj2[key];
      }
    });
  }

  // Start checking for changes
  checkChanges(originalObject, newObject, changes);

  return changes;
};

export const getEffectiveNotificationSettings = (
  clinic: Clinic,
  patient: Patient
) => {
  return {
    notify_low_potassium:
      patient?.notify_low_potassium ?? clinic?.notify_low_potassium,
    number_low_potassium:
      patient?.number_low_potassium ?? clinic?.number_low_potassium,
    notify_high_potassium:
      patient?.notify_high_potassium ?? clinic?.notify_high_potassium,
    number_high_potassium:
      patient?.number_high_potassium ?? clinic?.number_high_potassium,
    notify_dyskalemia: patient?.notify_dyskalemia ?? clinic?.notify_dyskalemia,
    number_dyskalemia: patient?.number_dyskalemia ?? clinic?.number_dyskalemia,
    notify_hct: patient?.notify_hct ?? clinic?.notify_hct,
    threshold_low_hct: patient?.threshold_low_hct ?? clinic?.threshold_low_hct,
    threshold_high_hct:
      patient?.threshold_high_hct ?? clinic?.threshold_high_hct,
    number_low_hct: patient?.number_low_hct ?? clinic?.number_low_hct,
    number_high_hct: patient?.number_high_hct ?? clinic?.number_high_hct,
    notify_hgb: patient?.notify_hgb ?? clinic?.notify_hgb,
    threshold_low_hgb: patient?.threshold_low_hgb ?? clinic?.threshold_low_hgb,
    threshold_high_hgb:
      patient?.threshold_high_hgb ?? clinic?.threshold_high_hgb,
    number_low_hgb: patient?.number_low_hgb ?? clinic?.number_low_hgb,
    number_high_hgb: patient?.number_high_hgb ?? clinic?.number_high_hgb,
  };
};

export const getPotassiumChartHeaderIcon = (
  clinic: Clinic,
  patient: Patient,
  data
) => {
  if (!data || data.length === 0) return NO_DATA_ICON_PATH;

  const settings = getEffectiveNotificationSettings(clinic, patient);
  if (clinic?.ordinal_potassium) {
    const lastValue = data.slice(-1)[0];
    if (lastValue.y.toUpperCase() === "HIGH") {
      const number_high_potassium = settings.number_high_potassium;
      const lastValues = data.slice(-number_high_potassium);
      if (
        Array.isArray(lastValues) &&
        lastValues.every(({ y }) => ["HIGH"].includes(`${y}`.toUpperCase()))
      ) {
        return ALERT_ICON_PATH;
      } else if (
        Array.isArray(lastValues) &&
        lastValues.some(({ y }) => ["HIGH"].includes(`${y}`.toUpperCase()))
      ) {
        return WARNING_ICON_PATH;
      } else {
        return ACTIVE_ICON_PATH;
      }
    } else if (lastValue.y.toUpperCase() === "LOW") {
      const number_low_potassium = settings.number_low_potassium;
      const lastValues = data.slice(-number_low_potassium);
      if (
        Array.isArray(lastValues) &&
        lastValues.every(({ y }) => ["LOW"].includes(`${y}`.toUpperCase()))
      ) {
        return ALERT_ICON_PATH;
      } else if (
        Array.isArray(lastValues) &&
        lastValues.some(({ y }) => ["LOW"].includes(`${y}`.toUpperCase()))
      ) {
        return WARNING_ICON_PATH;
      } else {
        return ACTIVE_ICON_PATH;
      }
    } else {
      let number_to_check = settings.number_low_potassium;
      if (settings.number_high_potassium > number_to_check) {
        number_to_check = settings.number_high_potassium;
      }
      const lastValues = data.slice(-number_to_check);
      if (
        Array.isArray(lastValues) &&
        lastValues.some(({ y }) =>
          ["HIGH", "LOW"].includes(`${y}`.toUpperCase())
        )
      ) {
        return WARNING_ICON_PATH;
      } else {
        return ACTIVE_ICON_PATH;
      }
    }
  } else {
    const number_dyskalemia = settings.number_dyskalemia;
    const lastValues = data.slice(-number_dyskalemia);
    if (
      Array.isArray(lastValues) &&
      lastValues.every(({ y }) =>
        ["HIGH", "LOW"].includes(`${y}`.toUpperCase())
      )
    ) {
      return ALERT_ICON_PATH;
    } else if (
      Array.isArray(lastValues) &&
      lastValues.some(({ y }) => ["HIGH", "LOW"].includes(`${y}`.toUpperCase()))
    ) {
      return WARNING_ICON_PATH;
    } else {
      return ACTIVE_ICON_PATH;
    }
  }
};

export const getHctChartHeaderIcon = (
  clinic: Clinic,
  patient: Patient,
  data
) => {
  if (!data || data.length === 0) return NO_DATA_ICON_PATH;

  const settings = getEffectiveNotificationSettings(clinic, patient);
  const lastValue = data.slice(-1)[0];
  if (lastValue.y < settings.threshold_low_hct) {
    const number_low_hct = settings.number_low_hct;
    const lastValues = data.slice(-number_low_hct);
    if (
      Array.isArray(lastValues) &&
      lastValues.every(({ y }) => y < settings.threshold_low_hct)
    ) {
      return ALERT_ICON_PATH;
    } else if (
      Array.isArray(lastValues) &&
      lastValues.some(({ y }) => y < settings.threshold_low_hct)
    ) {
      return WARNING_ICON_PATH;
    } else {
      return ACTIVE_ICON_PATH;
    }
  } else if (lastValue.y > settings.threshold_high_hct) {
    const number_high_hct = settings.number_high_hct;
    const lastValues = data.slice(-number_high_hct);
    if (
      Array.isArray(lastValues) &&
      lastValues.every(({ y }) => y > settings.threshold_high_hct)
    ) {
      return ALERT_ICON_PATH;
    } else if (
      Array.isArray(lastValues) &&
      lastValues.some(({ y }) => y > settings.threshold_high_hct)
    ) {
      return WARNING_ICON_PATH;
    } else {
      return ACTIVE_ICON_PATH;
    }
  } else {
    let number_to_check = settings.number_low_hct;
    if (settings.number_high_hct > number_to_check) {
      number_to_check = settings.number_high_hct;
    }
    const lastValues = data.slice(-number_to_check);
    if (
      Array.isArray(lastValues) &&
      lastValues.some(({ y }) => {
        return (
          y < settings.threshold_low_hct || y > settings.threshold_high_hct
        );
      })
    ) {
      return WARNING_ICON_PATH;
    } else {
      return ACTIVE_ICON_PATH;
    }
  }
};

export const getHgbChartHeaderIcon = (
  clinic: Clinic,
  patient: Patient,
  data
) => {
  if (!data || data.length === 0) return NO_DATA_ICON_PATH;
  const settings = getEffectiveNotificationSettings(clinic, patient);

  const lastValue = data.slice(-1)[0];
  if (lastValue.y < settings.threshold_low_hgb) {
    const number_low_hgb = settings.number_low_hgb;
    const lastValues = data.slice(-number_low_hgb);
    if (
      Array.isArray(lastValues) &&
      lastValues.every(({ y }) => y < settings.threshold_low_hgb)
    ) {
      return ALERT_ICON_PATH;
    } else if (
      Array.isArray(lastValues) &&
      lastValues.some(({ y }) => y < settings.threshold_low_hgb)
    ) {
      return WARNING_ICON_PATH;
    } else {
      return ACTIVE_ICON_PATH;
    }
  } else if (lastValue.y > settings.threshold_high_hgb) {
    const number_high_hgb = settings.number_high_hgb;
    const lastValues = data.slice(-number_high_hgb);
    if (
      Array.isArray(lastValues) &&
      lastValues.every(({ y }) => y > settings.threshold_high_hgb)
    ) {
      return ALERT_ICON_PATH;
    } else if (
      Array.isArray(lastValues) &&
      lastValues.some(({ y }) => y > settings.threshold_high_hgb)
    ) {
      return WARNING_ICON_PATH;
    } else {
      return ACTIVE_ICON_PATH;
    }
  } else {
    let number_to_check = settings.number_low_hgb;
    if (settings.number_high_hgb > number_to_check) {
      number_to_check = settings.number_high_hgb;
    }
    const lastValues = data.slice(-number_to_check);
    if (
      Array.isArray(lastValues) &&
      lastValues.some(({ y }) => {
        return (
          y < settings.threshold_low_hgb || y > settings.threshold_high_hgb
        );
      })
    ) {
      return WARNING_ICON_PATH;
    } else {
      return ACTIVE_ICON_PATH;
    }
  }
};
