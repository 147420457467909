import { defineComponent } from "vue";
import {
  addLicense,
  Bullet,
  Button,
  color,
  Container,
  math,
  p50,
  Picture,
  Template,
  Theme,
  Tooltip,
} from "@amcharts/amcharts5";
import { AxisRendererX, DateAxis, LineSeries } from "@amcharts/amcharts5/xy";
import { getEventIcon } from "@/shared/utils";

addLicense("AM5C316710730");

const ALIO_SUCCESS_GREEN_HEX = "#1AB83D";
const ALIO_SUCCESS_GREEN = color(ALIO_SUCCESS_GREEN_HEX);
const ALIO_RANGE_GREEN_HEX = "#EFFAEF";
const ALIO_RANGE_GREEN = color(ALIO_RANGE_GREEN_HEX);
const ALIO_PURPLE = color("#8768AD");
const ALIO_SECONDARY_PURPLE = color("#BCA3EB");
const ALIO_LIGHT_PURPLE = color("#DEC5FD");
const BLACK = color("#000");
const WHITE = color("#FFFFFF");

export default defineComponent({
  name: "ChartMixin",
  data() {
    return {
      ALIO_SUCCESS_GREEN_HEX: ALIO_SUCCESS_GREEN_HEX,
      ALIO_SUCCESS_GREEN: ALIO_SUCCESS_GREEN,
      ALIO_RANGE_GREEN_HEX: ALIO_RANGE_GREEN_HEX,
      ALIO_RANGE_GREEN: ALIO_RANGE_GREEN,
      ALIO_PURPLE: ALIO_PURPLE,
      ALIO_SECONDARY_PURPLE: ALIO_SECONDARY_PURPLE,
      ALIO_LIGHT_PURPLE: ALIO_LIGHT_PURPLE,
      BLACK: BLACK,
      WHITE: WHITE,
    };
  },
  methods: {
    getTheme(root) {
      class AlioTheme extends Theme {
        setupDefaultRules() {
          // Set theme rules here
          this.rule("Label").setAll({
            fontFamily: "Avenir Next",
            fontSize: 16,
            fill: color("#1E242B"),
          });

          // ALIO Scrollbar
          this.rule("RoundedRectangle", [
            "scrollbar",
            "main",
            "background",
          ]).setAll({
            fill: ALIO_LIGHT_PURPLE,
          });

          this.rule("RoundedRectangle", ["scrollbar", "thumb"]).states.create(
            "hover",
            {
              fill: ALIO_PURPLE,
            }
          );

          this.rule("RoundedRectangle", ["scrollbar", "thumb"]).states.create(
            "down",
            {
              fill: ALIO_PURPLE,
            }
          );

          this.rule("RoundedRectangle", ["scrollbar", "thumb"]).setAll({
            fill: ALIO_PURPLE,
          });

          this.rule("Button", ["scrollbar"]).setup = (target: Button) => {
            target.get("background").setAll({
              fill: ALIO_PURPLE,
            });
          };
        }
      }

      return AlioTheme.new(root);
    },

    createGrid(
      yAxis,
      value,
      disableLabel = false,
      thresholdLine = false,
      borderLine = false
    ) {
      const rangeDataItem = yAxis.makeDataItem({
        value,
      });
      const range = yAxis.createAxisRange(rangeDataItem);
      if (disableLabel) {
        range.get("label").setAll({
          disabled: true,
        });
      } else {
        range.get("label").setAll({
          text: value,
        });
      }

      if (thresholdLine) {
        range.get("grid").setAll({
          stroke: this.ALIO_PURPLE,
          strokeOpacity: 1,
          strokeWidth: 2,
        });
      }

      if (borderLine) {
        range.get("grid").setAll({
          stroke: this.BLACK,
          strokeOpacity: 1,
          strokeWidth: 1,
          forceHidden: false,
          layer: 10,
        });
      }
    },

    setupXAxis(chart, root, upperBound, lowerBound, extraSettings = {}) {
      const tooltip: Tooltip = Tooltip.new(root, {});

      // by default timeunit is minute, but on clinical2 it is second
      // so we need to check for the env variable
      let timeUnit = "minute";
      if ("VITE_BASE_INTERVAL" in import.meta.env) {
        // @ts-ignore
        timeUnit = import.meta.env.VITE_BASE_INTERVAL;
      }

      const settings = {
        maxDeviation: 0,
        max: upperBound * 1000,
        min: lowerBound * 1000,
        baseInterval: {
          timeUnit,
          count: 1,
        },
        renderer: AxisRendererX.new(root, {
          minGridDistance: 150,
        }) as AxisRendererX,
        tooltip,
        tooltipDateFormats: {
          minute: "hh:mm a",
          hour: "hh:mm a",
          day: "[bold]eee[/] MMM dd",
          week: "[bold]eee[/] MMM dd",
        },
        dateFormats: {
          minute: "hh:mm a",
          hour: "hh:mm a",
          day: "[bold]eee[/] MMM dd",
          week: "[bold]eee[/] MMM dd",
        },
        periodChangeDateFormats: {
          minute: "[bold]eee[/] MMM dd",
          hour: "[bold]eee[/] MMM dd",
          day: "[bold]eee[/] MMM dd",
          week: "[bold]eee[/] MMM dd",
        },
        ...extraSettings,
      } as any;

      const xAxis = chart.xAxes.push(DateAxis.new(root, settings));

      tooltip.get("background").setAll({
        fill: this.ALIO_PURPLE,
        fillOpacity: 1,
        strokeWidth: 0,
      });

      return xAxis;
    },

    getLineConnect() {
      if ("VITE_ALWAYS_CONNECT_LINES" in import.meta.env) {
        return import.meta.env.VITE_ALWAYS_CONNECT_LINES;
      } else {
        return false;
      }
    },

    addDialysisEventsSeries(root, chart, xAxis, yAxis, dialysisEvents) {
      const tooltip: Tooltip = Tooltip.new(root, {
        autoTextColor: false,
        labelText: "{realValue}",
        getFillFromSprite: false,
      });
      tooltip.label.setAll({
        fill: this.WHITE,
        fillOpacity: 1,
        strokeWidth: 0,
        strokeOpacity: 0,
      });
      tooltip.get("background").setAll({
        fill: this.ALIO_PURPLE,
        fillOpacity: 1,
        strokeWidth: 0,
        strokeOpacity: 0,
      });
      const dialysisSeries = chart.series.push(
        LineSeries.new(this.root, {
          name: "Dialysis Events",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          stroke: this.ALIO_SECONDARY_PURPLE,
          fill: this.ALIO_SECONDARY_PURPLE,
          connect: false,
          tooltip,
          showTooltipOn: "always",
        })
      );

      dialysisSeries.strokes.template.setAll({
        strokeOpacity: 0,
      });

      dialysisSeries.bullets.push((root, bullet, dataItem) => {
        const container: Container = Container.new(root, {
          centerX: p50,
          centerY: p50,
        });

        const svg: Picture = Picture.new(root, {
          src: dataItem.dataContext.icon,
          width: 30,
          height: 30,
        });

        container.children.push(svg);

        // Attach the container directly to the dataItem for easy access
        dataItem.set("eventBullet", container);

        return Bullet.new(root, {
          sprite: container,
        }) as Bullet;
      });

      dialysisSeries.data.setAll(dialysisEvents);

      dialysisEvents.forEach((event) => {
        // Create a data item for the vertical line
        const rangeDataItem = xAxis.makeDataItem({
          value: event.date, // Set the timestamp for the vertical line
        });

        // Create the axis range
        const range = xAxis.createAxisRange(rangeDataItem);

        // Customize the axis range to appear as a vertical line
        range.get("grid").setAll({
          stroke: color("#D7DFDF"), // Set the color of the line
          strokeWidth: 12, // Set the width of the line
          strokeOpacity: 1, // Set the opacity of the line
          visible: true, // Make sure the line is visible
          strokeDasharray: [4, 4], // Set the line to be dashed
        });
      });

      return dialysisSeries;
    },

    setupDialysisEvents(fixedPosition, excludeEvents = []) {
      if (!this.events) return [];
      return this.events
        .filter((event) => !excludeEvents.includes(event.type))
        .map((event) => {
          return {
            date: event?.event_ts * 1000,
            value: fixedPosition,
            realValue: this.$t(`events.${event.type}`),
            icon: getEventIcon(event.type),
          };
        });
    },

    getAutoGapCount(chartType, acute, acuteMode) {
      if (chartType === "temperature" || chartType === "availability") {
        return 30; // 30 minutes
      }
      if (acute) {
        // 1M = 1 minute
        if (acuteMode.includes("1")) {
          // 2 minutes means 2 points when data is at 1 minute interval
          return 2;
        } else {
          // the alternative is 2 and a half minute mode which means 5 minutes would be 2 missing data points
          return 5;
        }
      } else {
        return 360; // 360 minutes
      }
    },
  },
});
